import React, { useState } from 'react'
import styled from 'styled-components'
import { device } from './Device'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PinkFloydBackground from '../images/pf-background.png'
import BackgroundTriangleImage from '../images/bg-triangular.png'
import PinkFloydTriangle from '../images/pf-triangulo.png'


const Background = styled.div`
    background-image: url(${PinkFloydBackground});
    background-repeat: repeat;
`
const Container = styled.div`
    max-width: 1024px;
    margin: auto;
    padding: 140px 0 1000px;
    @media ${device.laptop} {
        max-width: 768px;
    }

    @media ${device.tablet} {
        max-width: 375px;
        padding-bottom: 825px;
        padding-top: 90px;
    }
`

const InfoContainer = styled.div`
    float: left;
    width: 50%;
    margin-top: 115px;
    @media ${device.tablet} {
        display: block;
        float: none;
        width: auto;
        margin: 0;
        padding: 0 20px;
    }
`

const ItemName = styled.span`
    text-transform: uppercase;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: #717171;
    opacity: 0.8;
`

const ItemTitle = styled.h1`
    margin-top: 15px;
    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 53px;
    letter-spacing: -0.01em;
    color: #3E3E3E;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.01);
`

const ContentDiv = styled.div`
    margin-top: 32px;
    font-family: Google Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #3E3E3E;
`

const Button = styled(Link)`
    margin-top: 32px;
    font-family: Google Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #C8625B;
    text-decoration: none;
    border-bottom: solid 1px #C8625B;
    padding-bottom: 7px;
    display: inline-block;
`


const ImageContainer = styled.div`
    float: right;
    width: 50%;
    @media ${device.tablet} {
        width: 100%;
        margin-top: 60px; 
    }
`

const TransformDiv = styled.div`
    position: absolute;
    background: #FCEEE1;
    opacity: 1;
    border-radius: 97px;
    width: 475px;
    height: 475px;
    transform: rotate(-80deg);
    z-index: -1;
    margin-left: -5px;
    @media ${device.laptop} {
        width: 350px;
        height: 350px;
        margin-left: 5px;
    }

    @media ${device.tablet} {
        display: none;
    }
`

const ItemImage = styled.img`
    padding: 0 23px;
    position: relative;
    z-index: 1;
    width: 465px;
    border-radius: 16px;

    @media ${device.laptop} {
        width: 300px;
    }

    @media ${device.tablet} {
        width: 100%;
        padding: 0;
    }
`

const ServicesContainer = styled.div`
    position: absolute;
    margin-top: -200px;
    height: 120px;
    width: 800px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 100px;
    @media ${device.laptop} {
        width: 600px;  
        padding: 0px 80px;      
    }

    @media ${device.tablet} {
        left: 0;
        width: 100%;
        padding: 0px;
        background: white;
    }
`

const ServiceItem = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    z-index: 21;
    cursor: pointer;
    height: 50px;
`
const ServiceItemName = styled.span`
    font-family: Google Sans;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${props => props.selected ? "white" : "#3E3E3E"}; #;
    padding-top: 8px;
    width: 90px;
    @media (max-width: 540px) {
        font-size: 10px;
        width: 40px;
    }
`
const BackgroundTriangle = styled.img`
    position: absolute;
    width: 1024px;
    height: 180px;
    margin-top: -225px;
    z-index: 20;

    @media ${device.laptop} {
        width: 768px;        
    }

    @media ${device.tablet} {
        display: none;
    }
`

const CustomTriangle = styled.img`
    z-index: -1;
    position: absolute;
    margin-top: -55px;
    width: 180px;
    @media ${device.tablet} {
        width: 96px;
        background: #3E3E3E;
        margin-top: -35px;
        height: 120px;
    }

    @media (max-width: 540px) {
        font-size: 10px;
        width: 78px;
    }
`

const OtherItems = styled.li`
     margin-bottom: 5px;
     margin-left: 16px;
     font-size: 16px;
     a {
         color: #C8625B;
     }
     list-style-type: circle;
 `


const CarouselMenu = () => {
    const { allWpCarrosselMenu } = useStaticQuery(
        graphql`
            query {
                allWpCarrosselMenu {
                    edges {
                        node {
                            id
                            title
                            content
                            info {
                              label
                              icon {
                                sourceUrl
                              }
                              selectedIcon {
                                sourceUrl
                              }
                              order
                            }
                            button {
                              text
                              link
                            }
                            bannerImage {
                              image {
                                sourceUrl
                              }
                            }
                        }
                    }
                }
            }
        `
    )
    const menuItems = allWpCarrosselMenu.edges.slice().sort((a, b) => a.node.info.order - b.node.info.order)
    console.log(menuItems);
    const [selectedItem, setSelectedItem] = useState(menuItems[0])
    return <Background>
        <Container>
            <BackgroundTriangle src={BackgroundTriangleImage} />
            <ServicesContainer>
                {menuItems.map((edge) => (
                    <ServiceItem onClick={() => setSelectedItem(edge)} key={edge.node.title}>
                        {selectedItem.node.id === edge.node.id && (<CustomTriangle src={PinkFloydTriangle} />)}
                        
                        <img width="24px" src={selectedItem.node.id === edge.node.id ? edge.node.info.selectedIcon.sourceUrl : edge.node.info.icon.sourceUrl} />
                        <ServiceItemName selected={selectedItem.node.id === edge.node.id}>{edge.node.info.label}</ServiceItemName>
                    </ServiceItem>
                ))}
            </ServicesContainer>
            <InfoContainer>
                <ItemName>{selectedItem.node.info.label}</ItemName>
                <ItemTitle>{selectedItem.node.title}</ItemTitle>
                {selectedItem.node.info.label == "outros" ? (
                     <ContentDiv>
                         <ul>
                             <OtherItems><Link to="servicos/registro-pet">Registro pet</Link></OtherItems>
                             <OtherItems><Link href="/servicos/apostilamento">Apostilamento</Link></OtherItems>
                             <OtherItems><Link href="/servicos/pedidos-de-2-via">Pedido de 2 via</Link></OtherItems>
                             <OtherItems><Link to="entrada-de-casamento">Pré Atendimento Entrada de Casamento</Link></OtherItems>
                         </ul>
                     </ContentDiv>
                 ) : (<>
                         <ContentDiv dangerouslySetInnerHTML={{ __html: selectedItem.node.content }} />
                         {selectedItem.node.button.link && <Button to={selectedItem.node.button.link}>{selectedItem.node.button.text}</Button>}
                     </>
                 )}
            </InfoContainer>
            <ImageContainer>
                <TransformDiv />
                <ItemImage src={selectedItem.node.bannerImage.image.sourceUrl} />
            </ImageContainer>
        </Container>
    </Background>
}

export default CarouselMenu
