import React from 'react'
import styled from 'styled-components';
import { Link } from 'gatsby'
import { device } from './Device'
import CNJImage from '../images/cnj.png'
import TJDFImage from '../images/tjdf.png'
import AnoregImage from '../images/anoreg.png'
import ArpenImage from '../images/arpen.png'

const Banner = styled.div`
    max-width: 1024px;
    margin: auto;
    margin-top: -200px;
    padding: 80px 0;
    background: radial-gradient(49.18% 165.33% at 99.22% 98.28%, rgba(224, 150, 91, 0.15) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(240.91deg, rgba(255, 255, 255, 0.15) 19.09%, rgba(255, 255, 255, 0) 67.87%), radial-gradient(39.49% 117.56% at 23.3% 64.62%, rgba(128, 113, 113, 0.28) 0%, rgba(0, 0, 0, 0) 100%), #8B7C70;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: view-box;
    @media ${device.laptop} {
        max-width: 768px;
    }

    @media ${device.tablet} {
        max-width: 375px;
    }

    @media ${device.mobileL} {
        width: 314px;
    }
`

const Title = styled.h1`
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 53px;
    text-align: center;
    letter-spacing: -0.01em;
    font-family: Playfair Display;
    color: #FFFFFF;
`

const StyledDiv = styled.div`
    font-family: Google Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin: auto;
    width: 400px;
    margin-top: 32px;

    @media ${device.tablet} {
        max-width: 300px;
    }
`

const Button = styled(Link)`
    background: #FFF;
    border-radius: 4px;
    font-family: Google Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    padding: 16px 0px;
    text-decoration: none;
    display: inline-block;
    margin: 40px auto 0;
    text-align: center;
    display: block;
    width: 197px;
`

const WhiteBackgroundDiv = styled.div`
    background: #FFFFFF;
    opacity: 0.05;
    border-radius: 77px;
    transform: matrix(-0.25, -0.97, 0.96, -0.27, 0, 0);
    position: absolute;
    width: 247px;
    height: 247px;
    margin-left: 750px;
    margin-top: -180px;
    z-index: -1;

    @media ${device.laptop} {
        width: 280px;
        height: 280px;
        margin-left: 440px;
        margin-top: -200px;
        border-radius: 50px;
    }

    @media ${device.tablet} {
        display: none;
    }
`

const DarkBackgroundDiv = styled.div`
    background: #3E3E3E;
    opacity: 0.05;
    border-radius: 97px;
    transform: matrix(0.33, -0.95, 0.93, 0.35, 0, 0);
    width: 862px;
    height: 862px;
    position: absolute;
    margin-left: -150px;
    margin-top: 150px;
    z-index: -1;

    @media ${device.laptop} {
        width: 331px;
        height: 331px;
        border-radius: 50px;
    }

    @media ${device.tablet} {
        display: none;
    }
`

const ImageContainer = styled.div`
    max-width: 1024px;
    margin: auto;
    padding: 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;

    @media ${device.laptop} {
        max-width: 768px;
    }

    @media ${device.tablet} {
        max-width: 375px;
        padding-left: 0;
        padding-right 0;
    }
`

const PartnersDiv = styled.div`
    background: #FCEEE1;
    opacity: 1;
    margin-top: -180px;
    padding-top: 180px;
    padding-left: 120px;
    padding-right: 120px;
    @media ${device.tablet} {
        margin-top: -254px;
        padding-left: 60px;
        padding-right: 60px;
    }
`

const SecondaryBanner = (props) => {
    const { content } = props
    return (<div>
            <Banner>
                <WhiteBackgroundDiv></WhiteBackgroundDiv>
                <DarkBackgroundDiv></DarkBackgroundDiv>
                <Title>{content.title}</Title>
                <StyledDiv dangerouslySetInnerHTML={{ __html: content.content }} />
                <Button to={content.button.link}>{content.button.text}</Button>
            </Banner>
            <PartnersDiv>
                <ImageContainer>
                    <img width="114" src={CNJImage} />
                    <img width="98" src={TJDFImage} />
                    <img width="92" src={AnoregImage} />
                    <img width="158" src={ArpenImage} />
                </ImageContainer>
            </PartnersDiv>
        </div>
    )
}

export default SecondaryBanner