import React from 'react'
import styled from 'styled-components';
import { Link } from 'gatsby'
import { device } from './Device'

const Banner = styled.div`
    width: 100%;
    padding-top: 150px;
    padding-bottom: 260px;
    background-repeat: no-repeat;
    background-size: cover;
    @media ${device.laptop} {
        background-position: center bottom;
    }

    @media ${device.mobileL} {
        background-position: 60% 0%;
    }
`

const Container = styled.div`
    margin: auto;
    width: 1024px;

    @media ${device.laptop} {
        width: 768px;
    }

    @media ${device.tablet} {
        width: 425px;
    }

    @media ${device.mobileL} {
        width: 314px;
    }
`

const InfoContainer = styled.div`
    max-width: 480px;
    margin-left: 20px;
`
const Title = styled.h1`
    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 53px;
    letter-spacing: -0.01em;
    color: #ECE9EC;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.01);
   
`
const StyledDiv = styled.div`
    font-family: Google Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 32px
`

const Button = styled(Link)`
    margin-top: 40px;
    background: #C8625B;
    border-radius: 4px;
    font-family: Google Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    padding: 16px 38px;
    text-decoration: none;
    display: inline-block;
`

const ResponsiveImage = styled.img`
    max-width: 100%;
    height: auto;
`

const MainBanner = (props) => {
    const { content } = props
    return (
        <Banner style={{ "backgroundImage": `url(${content.bannerImage.image.sourceUrl})`}}>
            <Container>
                <InfoContainer>
                    <Title>{content.title}</Title>
                    <StyledDiv dangerouslySetInnerHTML={{__html: content.content}} />
                    <Button to={content.button.link}>{content.button.text}</Button>
                </InfoContainer>
            </Container>
        </Banner>
    )
}

export default MainBanner