import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import MainBanner from '../components/MainBanner'
import SEO from '../components/SEO'
import SecondaryBanner from '../components/SecondaryBanner'
import CarouselMenu from '../components/CarouselMenu'
import LocationMap from '../components/LocationMap'
import LocationInfo from '../components/LocationInfo'

const IndexPage = () => {
  const { allWpHomeBanner } = useStaticQuery(
    graphql`
          query {
              allWpHomeBanner {
                nodes {
                  title
                  content
                  bannerImage {
                    image {
                      sourceUrl
                    }
                  }
                  bannerType {
                    isMainBanner
                  }
                  
                  button {
                    text
                    link
                  }
                }
              }
            }  
    `
  )
  const mainBanner = allWpHomeBanner.nodes.find(node => node.bannerType.isMainBanner === true)
  const secondaryBanner = allWpHomeBanner.nodes.find(node => node.bannerType.isMainBanner === null)
  return (<Layout>
    <SEO title="Início" keywords={[`cartório`, `ceilândia`, `casamento`, `7`, `ofício`, `registros`, `títulos`, `documentos`]} />
    <MainBanner content={mainBanner} />
    <CarouselMenu />
    <SecondaryBanner content={secondaryBanner} />
    <LocationMap />
    <LocationInfo />
  </Layout>)
}

export default IndexPage