import React, { Component } from 'react';
import styled from 'styled-components';
import { device } from './Device'

const MapFrame = styled.iframe`
  border: 0;
  width: 50%;
  height: 900px;

  @media ${device.tablet} {
    width: 100%;
  }
`

class LocationMap extends Component {
  render() {
    return (
      <MapFrame
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3838.7417783692013!2d-48.102012634277344!3d-15.817564010620117!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935bcce98640e8ad%3A0x82724e6d22e33e85!2s3%20-%20St.%20M%20CNM%201%20-%20Ceil%C3%A2ndia%2C%20Bras%C3%ADlia%20-%20DF%2C%2072215-110!5e0!3m2!1spt-BR!2sbr!4v1614230459696!5m2!1spt-BR!2sbr"
        frameborder="0"
        allowfullscreen
        aria-hidden="false"
        tabindex="0" />
    )
  }
}

export default LocationMap;